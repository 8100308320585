import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { Theme } from '@mui/material';
import { getLocaleFromDateFns } from '@sekoia/shared/helpers/date-fns/getLocalFromDateFns';
import { useTranslation } from 'react-i18next';

export type InputDateFieldProps = {
  value: moment.Moment | undefined;
  label?: string;
  onInputChange: (value: moment.Moment | undefined) => void;
  theme?: Theme; //TODO: Remove in a cleanup pr
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  autoFocus?: boolean;
  minDate?: moment.Moment | undefined;
  maxDate?: moment.Moment | undefined;
  startInOpenState?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  onBlur?: (e: React.FocusEvent) => void;
  name?: string;
  tabIndex?: number;
  margin?: 'dense' | 'normal' | 'none';
  openToValue?: 'year' | 'month' | 'day';
  required?: boolean;
  clearButton?: boolean;
};

function InputDateField({
  value,
  label,
  onInputChange,
  disabled,
  disableFuture = true,
  disablePast = false,
  autoFocus = false,
  minDate,
  maxDate,
  startInOpenState = false,
  fullWidth = true,
  error,
  helperText,
  onBlur,
  tabIndex,
  name,
  margin = 'none',
  openToValue = 'day',
  required = false,
  clearButton = false,
}: InputDateFieldProps) {
  const [tabbedIn, setTabbedIn] = useState(false);
  const [isOpen, setIsOpen] = useState(startInOpenState);
  const { t } = useTranslation(['global']);

  const handleChange = useCallback(
    (value: Date | null | undefined) => {
      if (value) {
        onInputChange(moment(value));
      } else {
        onInputChange(undefined);
      }
    },
    [onInputChange],
  );

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent) => {
      setTabbedIn(ev.key === 'Tab');
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const clickOnFocus = useCallback(
    (event: React.FocusEvent<HTMLDivElement>) => {
      if (tabbedIn) {
        event.target.click();
        setTabbedIn(false);
      }
    },
    [tabbedIn],
  );

  const format = moment.localeData().longDateFormat('L').replace(/Y/g, 'y').replace(/D/g, 'd');

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={getLocaleFromDateFns()}
      localeText={{ clearButtonLabel: t('global:inputs.date.clear') }}
    >
      <DatePicker
        slotProps={{
          actionBar: {
            actions: clearButton ? ['clear'] : [],
          },
          textField: {
            helperText: helperText,
            fullWidth: fullWidth,
            onBlur: onBlur,
            tabIndex: tabIndex,
            onFocusCapture: clickOnFocus,
            name: name,
            margin: margin,
            error: error,
            required: required,
            InputLabelProps: {
              shrink: true,
            },
          },
        }}
        onChange={handleChange}
        label={label}
        value={value ? value.toDate() : null} // Null is important, this is how the component shows and empty control
        openTo={openToValue}
        views={['year', 'month', 'day']}
        disableFuture={disableFuture}
        disabled={disabled}
        disablePast={disablePast}
        autoFocus={autoFocus}
        minDate={minDate?.toDate()}
        maxDate={maxDate?.toDate()}
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        format={format}
        displayWeekNumber
      />
    </LocalizationProvider>
  );
}

export default InputDateField;
