/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { colors, createTheme as createMuiTheme, responsiveFontSizes, ThemeOptions } from '@mui/material';
import typography from './typography';
import { softShadows } from './shadows';
import StyleConstants from 'constants/StyleConstants';
import { getThemeSpacingFromUnit } from '@sekoia/shared/utils/styleHelper';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    dark?: string;
  }
}

const baseTheme = createMuiTheme({
  direction: 'ltr',
  typography,
  palette: {
    mode: 'light',
    action: {
      active: colors.blueGrey[600],
    },
    background: {
      default: colors.common.white,
      dark: '#f4f6f8',
      paper: colors.common.white,
    },
    primary: {
      main: StyleConstants.PRIMARY_COLOR,
    },
    secondary: {
      main: StyleConstants.SECONDARY_COLOR,
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
    error: {
      main: colors.red[700],
    },
  },
  shadows: softShadows,
});

const sekoiaOverrides: ThemeOptions = {
  components: {
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: 200,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...{
            [theme.breakpoints.down('xl')]: {
              minWidth: 'initial',
            },
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: StyleConstants.PRIMARY_COLOR,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          whiteSpace: 'pre-line',
        },
        body2: {
          whiteSpace: 'pre-line',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          hyphens: 'auto',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderBottom: `1px solid rgba(0,0,0,0.12)`,

          '&[data-clickable="true"]': {
            cursor: 'pointer',
          },
        },
        head: {
          '&:hover': {
            backgroundColor: colors.common.white,
          },
          borderBottom: `1px solid rgba(0,0,0,0.12)`,
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid rgba(0,0,0,0.12)`,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...{
            '&:last-child': {
              paddingBottom: theme.spacing(2),
            },
          },
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...{
            padding: theme.spacing(2, 2, 1),
          },
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...{
            padding: theme.spacing(1, 2),

            '& > .MuiList-root': {
              margin: theme.spacing(-1, -2),
              width: `calc(100% + calc(${getThemeSpacingFromUnit(2)} * 2))`,
            },
          },
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...{
            padding: theme.spacing(1, 2, 2),
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: ({ theme }) => ({
          ...{
            '&:focus': {
              backgroundColor: theme.palette.common.white,
            },
          },
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...{
            marginTop: theme.spacing(1),
          },
        }),
        li: {
          '& > span, & > a': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 300,
            display: 'block',
          },
        },
      },
    },
  },
};

export function createTheme() {
  let theme = responsiveFontSizes(baseTheme);
  theme = createMuiTheme(theme, sekoiaOverrides);
  return theme;
}
